import React from 'react';
import AboutList from "./AboutList";
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";


const AboutEcotec = () => {

    return (

        <section className="about-section" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-sm-12 about_bottom_warp">
                        <div className="about_list">
                            {/* Start: Heading */}
                            <SectionTitle
                                title=" Welcome to ECOTEC "
                            />


                            <p>ECOTEC is a leading organization in Environmental, Social and Governance (ESG), sustainability and supply chain services. We deliver one stop services that consists of Assessment, Advisory, TAX & Legal and Wealth Management that drive positive impact</p>
                            <p>ECOTEC is a global Audit and Assurance audit firm with professional services covering social compliance, sustainability, C-TPAT, green and low carbon, food safety and quality system etc.</p>
                            <p>We are committed to enhancing the ESG (Environmental, Social and Governance) competitiveness for brands and retailers supply chains, as well as to creating a transparent and reliable supply chain for our clients.</p>
                            <p>Founded in 2016– the global assurance provider – ECOTEC has nearly seven years of in-factory experience. We are headquartered in Dhaka, Bangladesh and have 3 offices in the world. Our team of 25-plus industry experts support clients in more than 7 countries, which includes about 11 corporate clients that work with ECOTEC as a trusted due diligence partner.</p>
                            <p>It is increasingly clear that companies will have to transform their programs to meet a new higher standard of due diligence. This requires a commitment to transparency, visibility and thoughtful management of risk and performance. It requires a commitment to business-driven sustainability.
                                We collaborate, we innovate, we ECOTEC.</p>

                            <strong>Our Vision:</strong>
                            ECOTEC inaugurated with a motto to
                            <ul>
                                <li>Leading as sustainability and ESG professional in the country</li>
                                <li>Building a sustainable working world.</li>
                                <li>Ensuring employees basic rights in the working place</li>
                                <li>Empowering female workers and helping to regain their rights</li>
                                <li>Proving a safer working environment</li>
                                <li>Implementing 3 R’s (Reduce, Reuse, Recycle) and help to reduce carbon footprint</li>
                                <li>Provide effective training to stakeholders</li>
                                <li>Provide supplement services, such as Tax, legal support, and IT</li>
                                <li>Helping on Investment Module</li>
                            </ul>

                            <strong>Our Mission:</strong>
                            <p>We are committed to enhancing the ESG (Environmental, Social and Governance) competitiveness for brands and retailers supply chains, as well as to creating a transparent and reliable supply chain for our clients.</p>

                        </div>

                    </div>

                    {  /*Start: About Image  */}
                    <div className="col-md-6 col-sm-12">

                        { /*  About Right Image  */}
                        <img src={process.env.PUBLIC_URL + '/assets/images/about.png'} alt="" />

                    </div>
                    {/*End: About Image  */}


                </div>
                {/* row */}
            </div>
            {/* container */}
        </section>


    );
};

export default AboutEcotec;
