import React from 'react';   
import AboutList from "./AboutList"; 
import SectionTitle from "../../SectionTitles/SectionTitle";
import AboutData from "../../../data/About/about-one";
 

const AboutShort = () => {

  return (
 
	<section className="about-section" id="about">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 col-sm-12 about_bottom_warp">
                    <div className="about_list">
                        {/* Start: Heading */}
                        <SectionTitle 
                        title=" Welcome to ECOTEC "  
                        />
                     
                
                        <p>ECOTEC is a leading organization in Environmental, Social and Governance (ESG), sustainability and supply chain services. We deliver one stop services that consists of Assessment, Advisory, TAX & Legal and Wealth Management that drive positive impact</p>
                        <p>ECOTEC is a global Audit and Assurance audit firm with professional services covering social compliance, sustainability, C-TPAT, green and low carbon, food safety and quality system etc.</p>
                        <p>We are committed to enhancing the ESG (Environmental, Social and Governance) competitiveness for brands and retailers supply chains, as well as to creating a transparent and reliable supply chain for our clients.</p>
                     
                     
                     </div>

                    <div className="row about_list_warp"> 

                       
                        
                    </div>
                </div>

                {  /*Start: About Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  About Right Image  */}
                    <img src={process.env.PUBLIC_URL + '/assets/images/about.png'} alt="" />

                </div> 
                {/*End: About Image  */}
            

            </div>
            {/* row */}
        </div>
        {/* container */}
    </section>


  );
};

export default AboutShort;
