import React from 'react';
import {Link} from "react-router-dom";
import navbarData from "../../../data/Navbar/navbar";
import MenuTab from '../../MenuTabs/MenuTab';

function NavbarItem(props) {
    return(
        navbarData.map(item=>(
            <li kEY={item.id}
                className={item.subMenu1 || item.megaMenu ? 'menuItemHasChildren' : ''}
            >
                <Link to={`${process.env.PUBLIC_URL + item.link}`}>{item.title}</Link>
                {(() => {
                    if(item.subMenu1){
                        return(
                            <ul className="subMenu pb-5" style={{ width:"100%",minHeight:"300px" }}>
                                <MenuTab/>
                            </ul>
                        )
                    }

 
                })()}
            </li>
        ))
    )
}

export default NavbarItem