import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";

import DataService from "../../services/AllService";

const MenuTab = () => {
  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    retrieveServices();
  }, []);

  const retrieveServices = () => {
    DataService.getAllServices()
      .then((response) => {
        setServiceList(response.data.data);
        console.log("data", response.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Tabs
      defaultActiveKey={serviceList.title}
      className="myClass mb-4 d-flex justify-content-around"
    >
      {serviceList.map((service) => (
        <Tab
          key={service.id}
          tabClassName="py-4 px-5 col text-center"
          eventKey={service.title}
          title={service.title}
          onSelect={(key) => console.log(key)}
        >
          <div className="container">
            <div className="row">
              {service.service_category.map((catService) => (
                <div className="col">
                  <h6 className="fs-5 mt-0 mb-3">{catService.title}</h6>

                  {catService.services.map((item) => (
                    <>
                      <p className="m-0 pb-3 mb-3" style={{ fontSize: "1rem" }}>
                        <Link to={`service/details?id=`+item.id}> {item.title}</Link>
                      </p>                     
                    </>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Tab>
      ))}
    </Tabs>
  );
};

export default MenuTab;

{
  /* <Tab tabClassName="py-4 px-5 col text-center" eventKey="accounting" title="ACCOUNTING" onSelect={(key)=>console.log(key)}>
          <div className="container">
            <div className="row">
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">ASSURANCE</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Financial Statement Audits</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Employee Benefit Plans</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Public Company/SEC</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Internal Audit/SOX</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">SOC Examinations</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Contract Compliance</Link>
                </p>
                <p className="m-0 pb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">ESG Audits</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">Tax</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Financial Statement Audits</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Employee Benefit Plans</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Public Company/SEC</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Internal Audit/SOX</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">SOC Examinations</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Contract Compliance</Link>
                </p>
                <p className="m-0 pb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">ESG Audits</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">Advisory</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Financial Statement Audits</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Employee Benefit Plans</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Public Company/SEC</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Internal Audit/SOX</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">SOC Examinations</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Contract Compliance</Link>
                </p>
                <p className="m-0 pb-3 " style={{ fontSize:'1rem' }}>
                  <Link href="#">ESG Audits</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">International</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Financial Statement Audits</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Employee Benefit Plans</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Public Company/SEC</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Internal Audit/SOX</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">SOC Examinations</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Contract Compliance</Link>
                </p>
                <p className="m-0 pb-3 " style={{ fontSize:'1rem' }}>
                  <Link href="#">ESG Audits</Link>
                </p>
              </div>
            </div>
          </div>
        </Tab>
        <Tab tabClassName="py-4 px-5 col text-center" eventKey="consulting" title="CONSULTING">
          <div className="container">
            <div className="row">
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">TECHNOLOGY</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Automation</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Data Analytics & BI</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Development & Integration</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Enterprise Systems</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Technology Products</Link>
                </p>
                <p className="m-0 pb-3 " style={{ fontSize:'1rem' }}>
                  <Link href="#">Technology Strategy</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">RISK & IT COMPLIANCE</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Cybersecurity</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">FedRAMP</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">IT Compliance</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">SOC Examinations</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">STRATEGY & OPERATIONS</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Organizational Planning</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Performance Audits</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Succession Planning</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">TRANSACTIONS</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Due Diligence</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">M&A Tax</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Merger Management</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Postacquisition Dispute</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Restructuring</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Valuations</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">SPECIALTY</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Financial Services</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Health Care</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Telecommunications</Link>
                </p>
              </div>
            </div>
          </div>
        </Tab>
        <Tab tabClassName="py-4 px-5 col text-center" eventKey="Wealth management" title="WEALTH MANAGEMENT">
          <div className="container">
            <div className="row">
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">INDIVIDUAL</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Tax</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Financial Planning</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Investments</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Family Office</Link>
                </p>
              </div>
              <div className="col">
                <h6 className="fs-5 mt-0 mb-3">INSTITUTIONAL</h6>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Investments</Link>
                </p>
                <p className="m-0 pb-3 mb-3" style={{ fontSize:'1rem' }}>
                  <Link href="#">Insurance</Link>
                </p>
              </div>
            </div>
          </div>
        </Tab> */
}
