import React, { Fragment, useEffect, useState } from "react";
import PageHeader from '../../components/PageHeader'
import EnvironmentalServices from '../../data/Services/EnvironmentalServices.json'
import SocialServices from '../../data/Services/SocialServices.json'
import GovernanceServices from '../../data/Services/GovernanceServices.json'
import ESGServices from '../../data/Services/ESGServices.json'
import ProgramServices from '../../data/Services/ProgramServices.json'
import OtherServices from '../../data/Services/OtherServices.json'
import PageWrapper from "../../components/PageWrapper";
import RelatedServices from "../../components/RelatedServices";
import ServiceContent from "./ServiceContent";
import DataService from "../../services/AllService";

const ServiceDetails = () => {

	const serviceID = new URLSearchParams(window.location.search).get("id");
	const [service, setService] = useState([]);
	const [serviceList, setServiceList] = useState([]);

	  useEffect(() => {
		if (serviceID)
		getServiceDetails(serviceID);
		getCategoryWiseService(service.service_category_id);
	  }, [serviceID]);

	  const getServiceDetails = id => {
		DataService.getServiceDetails(id)
		  .then(response => {
			setService(response.data.data);
			console.log('details',response.data.data);
		  })
		  .catch(e => {
			console.log(e);
		  });
	  };

	  const getCategoryWiseService = id => {
		DataService.getCategoryWiseService(id)
		  .then(response => {
			  setServiceList(response.data.data);
			console.log('getCategoryWiseService',response.data.data);
		  })
		  .catch(e => {
			console.log(e);
		  });
	  };






	// if(serviceID >= 1 && serviceID <= 7){
	// 	serviceList = EnvironmentalServices
	// 	service = EnvironmentalServices.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	// }

	// if(serviceID >= 8 && serviceID <= 17){
	// 	serviceList = SocialServices
	// 	service = SocialServices.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	// }

	// if(serviceID >= 18 && serviceID <= 22){
	// 	serviceList = GovernanceServices
	// 	service = GovernanceServices.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	// }

	// if(serviceID >= 23 && serviceID <= 27){
	// 	serviceList = ESGServices
	// 	service = ESGServices.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	// }
	

	// if(serviceID >= 28 && serviceID <= 34){
	// 	serviceList = ProgramServices
	// 	service = ProgramServices.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	// }

	// if(serviceID >= 35 && serviceID <= 40){
	// 	serviceList = OtherServices
	// 	service = OtherServices.find((serviceItem) => serviceItem.id === parseInt(serviceID));
	// }
	

	return (
		<Fragment>

			<PageHeader
				bgImg={(process.env.PUBLIC_URL + '/assets/images/page_bg.jpg')}
				title={service.title}
			/>

			<PageWrapper classes="single_service_section">

				<ServiceContent
					service={service}
					serviceList={serviceList}
				/>
			</PageWrapper>

			<RelatedServices serviceList={serviceList} />


		</Fragment>
	);
};

export default ServiceDetails;



